<template>
<div class="container p-3">
<h1 style="text-align: center;">Privacy Policy</h1>
    <p >Elation Edtech Pvt Ltd. has developed the Let's Tinker as a Free app. This SERVICE is provided to you by Elation Edtech Pvt Ltd. at no cost. The service is meant to be used as it is.
This page is designed with the aim of informing our app users regarding our privacy policies associated with the gathering, use, and disclosure of personal information if anyone decided to use our Service.
If you choose our Service, then you want to comply with the gathering and use of data about this privacy policy. All the personal information that we'll collect is utilized for providing and improving our Service. We'll not use or share any of your personal information with anyone except as described in this Privacy Policy.
    </p>
    <h3>Information Collection and Usage</h3>
    <p>
        In order to give you a better user experience while using our service, we may require you to supply us with certain personally identifiable information, such as your Full Name, Mobile Number, Email Address, Class, School Name, etc. The personal information that we request from you will be retained by us. It will be utilized as mentioned under this privacy policy.
    </p>
    <h3>What Type of Personal Data Do We Collect From Our App Users?</h3>
    <p>We collect the following Personal Data from our users:</p>
    <ul>
        <li>It includes personal information of you or your child such as (name, email address, mobile number, location, photograph, gender, date of birth for the purpose of validating the age. </li>
        <li>Our online sessions are recorded for the purposes of better quality audits and teacher training.</li>
        <li>We need user's permission to access their device's media library so that they can upload their photos, videos, and projects directly into the app.</li>
    </ul>

    <h3>How Do We Use Your Personal Data?</h3>
    <ul>
        <li>For the purpose of verifying the identity of our app users.</li>
        <li>For evaluating, developing, and improving our product and services. </li>
        <li>For the purpose of handling complaints and inquiries. </li>
        <li>For the purpose of sending you information about our latest offers & discounts on our product and services that you may be interested in. ( You can choose to opt-out of these promotional emails by clicking on the "Unsubscribe" link at the bottom of each footer mail.)</li>
        <li>By taking the required permission to post and display the projects or apps created by your child using our app or related pages or any social media platforms along with your personal information as per the applicable law. </li>
        <li>For the purpose of communicating with you regarding our existing product used by you. It includes notifications of any alert or any kind of updates or survey.</li>

    </ul>
    <h3>Log Data</h3>
    <p>We want to tell you that whenever you utilize our Service, just in case of a mistake within the app, we may collect data and knowledge (through third-party products) on your phone called Log Data. This Log Data contains all the information that is related to your device's Internet Protocol (IP) address, device name, OS version, the configuration of the app, time and date of your usage, and other statistics.</p>
    <h3>Cookies</h3>
    <p>The Cookies are a special type of files that contain a small amount of information that is commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you simply have visited in the past. These cookies are stored in your device's internal memory.
This Service doesn't use these “cookies” explicitly. However, the app may use third-party code and libraries that will use “cookies” to gather information and improve their services. You will get an option to either accept or refuse these cookies.  In case, you choose to refuse our cookies, then in that case you'll not be ready to use some portions of our Service.
    </p>
    <h3>Service Providers</h3>
    <p>We may employ third-party companies and individuals for the following reasons that are listed down below:</p>
    <ul>
        <li>In order to facilitate our Service;</li>
        <li>In order to supply the Service on our behalf;</li>
        <li>In order to perform Service-related services; or</li>
        <li>In order to help us in analyzing how our Service is employed.</li>
    </ul>
    <p>We want to inform our app users that these third parties have access to their Personal Information. These third parties use this personal information to perform the tasks assigned to them on our behalf. However, they're obligated to not disclose or use the private information for other purposes.</p>
    <h3>Security</h3>
    <p>We always value our app user's trust in sharing with us their personal information. We will use all the commercially acceptable means of protecting their personal information. But please remember that there's no method of transmission over the web or any method of electronic storage that's 100% secure and reliable, and that is why we cannot guarantee its absolute security.</p>
    <h3>Links to Other Sites</h3>
    <p>This Service may contain some links that will redirect you to some other sites. In case, you click on a third-party link, then you'll be redirected to a particular site. Please note that these external sites aren't operated by us. Therefore, we advise you to please read the Privacy Policy of these external websites very carefully. We have absolutely no control over the third-party sites or services. We assume no responsibility for any of the content, privacy policies, or any kind of practices of the third-party sites or services.</p>
    <h3>Children’s Privacy</h3>
    <p>Our Services don't address the children who are under the age of 13. We don't knowingly collect personally identifiable information from the children who are under the age of 13. In case we discover that a child under the age of 13 has provided us with his/her personal information, then we'll immediately delete their information from our servers. If you're a parent or a guardian and you're aware that your child has provided us with his/her personal information, then please contact us so that we'll do the required action.</p>
    <h3>Changes to the present Privacy Policy</h3>
    <p>These Privacy Policies are subjected to change from time to time. We may update our Privacy Policy. Thus, you're advised to please review this page frequently to notice any kinds of new changes. In case we change our privacy policy, then we'll notify you about the policy modifications by posting on this page. These new changes in the Privacy Policy are effective immediately after they're posted on this page.</p>
    <h3>Contact Us</h3>
    <p>If you've got any questions or doubts about our Privacy Policy, App usage, course subscription, demo booking, etc. don't hesitate to contact us at developer@tinker.ly.</p>
</div>
</template>
<script>
import '../assets/css/bootstrap.css'
export default {
        name: 'PrivacyPolicy',
        mounted: function () {
            window.__lc = window.__lc || {};
            window.__lc.license = 13188978;
            (function (n, t, c) {
                function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) }
                var e = {
                    _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () {
                        i(["once", c.call(arguments)])
                    }, off: function () { i(["off", c.call(arguments)]) },
                    get: function () {
                        if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)])
                    }, call: function () { i(["call", c.call(arguments)]) }, init: function () {
                        var n = t.createElement("script");
                        n.async = !0, n.type = "text/javascript",
                            n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
                    }
                };
                !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
            }(window, document, [].slice))
        }
}
</script>
